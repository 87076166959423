import Vue from "vue";
import { router } from "./router";
import store from "./store";
import { filters } from "./packages/cassandra-base/utilities";
import { listener, status } from "@/packages/cassandra-base/mixins";
import App from "./App";
import appAlert from "@/packages/cassandra-base/plugins/appAlert";
import vuetify from "./packages/cassandra-base/plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "nprogress/nprogress.css";
import VueEllipseProgress from "vue-ellipse-progress";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// import the theme
import confirmation from "@kinetics254/cassandra-base/plugins/confirmation";
// packages
import Dashboard from "./packages/cassandra-base";
import Leave from "./packages/cassandra-leave";
// import Leave from "@kinetics254/cassandra-leave";
import Profile from "./packages/cassandra-employee-profile";
import Setup from "./packages/cassandra-setup";
//import Imprest from "./packages/cassandra-imprest";
//import Payslip from "./packages/cassandra-payslip";
import Pettycash from "./packages/cassandra-pettycash";
import HandBook from "@kinetics254/cassandra-handbook";
import Advance from "./packages/cassandra-advance";
import Appraisal from "./packages/cassandra-appraisal";
//import Requisition from "./packages/cassandra-requisition";
//import Timesheet from "./packages/cassandra-timesheet";
// import Approvals from "@kinetics254/cassandra-approvals";
import Approvals from "./packages/cassandra-approvals";
import Feedback from "@kinetics254/summit-odyssey";
// eslint-disable-next-line no-global-assign
import Exit from "./packages/cassandra-exit";
import Disciplinary from "./packages/cassandra-disciplinary";
import Training from "./packages/cassandra-training";
// eslint-disable-next-line no-global-assign
Event = new Vue();

Vue.prototype.appName = process.env.MIX_VUE_APP_NAME;
Vue.use(VueEllipseProgress);
Vue.use(appAlert);
Vue.use(confirmation);
Vue.use(Toast);
filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});
const options = { router, store };
Vue.use(Dashboard, options);
Vue.use(Leave, options);
Vue.use(Profile, options);
Vue.use(Setup, options);
Vue.use(Appraisal, options);
Vue.use(Feedback, options);
//Vue.use(Imprest, options);
//Vue.use(Payslip, options);
Vue.use(Pettycash, options);
Vue.use(HandBook, options);
Vue.use(Advance, options);
//Vue.use(Requisition, options);
//Vue.use(Timesheet, options);
Vue.use(Approvals, options);
Vue.use(Exit, options);
Vue.use(Disciplinary, options);
Vue.use(Training, options);
// eslint-disable-next-line no-new
new Vue({
  el: "#app",
  router,
  store,
  mixins: [status, listener],
  vuetify,
  render: (h) => h(App),
});
