import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c(VCard,{staticClass:"mx-auto",attrs:{"flat":"","width":"80%"}},[_c(VCardTitle,[_vm._v(" Sign in ")]),_c(VCardText,[_c(VForm,{ref:"loginForm",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Email Address","append-icon":"email","rules":[_vm.rules.email.required, _vm.rules.email.value]},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c(VTextField,{attrs:{"outlined":"","dense":"","append-icon":_vm.showPassword ? 'visibility' : 'visibility_off',"rules":[_vm.rules.password.required, _vm.rules.password.min],"type":_vm.showPassword ? 'text' : 'password',"name":"password","label":"Password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('div',[_c('router-link',{attrs:{"to":"/auth/reset"}},[_c('small',[_vm._v("Forgot password?")])])],1),_c(VBtn,{staticClass:"mt-2 text-capitalize",attrs:{"color":"primary","block":"","type":"submit"}},[_c('div',[_vm._v("Login")]),_c(VIcon,{staticClass:"mx-2"},[_vm._v("login")])],1)],1),_c('div',{staticClass:"mt-4"},[_c('div',[_vm._v("Dont have an account ?")]),_c(VBtn,{staticClass:"text-capitalize",attrs:{"link":"","color":"orange darken-2","to":{ name: 'Credentials' },"outlined":"","block":""}},[_vm._v(" Activate ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }