import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticStyle:{"background-color":"#ffffff"},attrs:{"app":""}},[_c(VBtn,{attrs:{"icon":"","x-large":""},on:{"click":_vm.changeDrawer}},[_c(VAvatar,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("apps")])],1)],1),_c(VToolbarTitle,{staticClass:"text-lg-h5",staticStyle:{"font-weight":"300"}},[_vm._v("ESS")]),_c(VSpacer),_c(VMenu,{attrs:{"close-on-content-click":true,"offset-y":"","content-class":"elevation-1","tile":"","nudge-bottom":"10","nudge-left":"25"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBadge,{staticClass:"mx-2 px-2",attrs:{"bordered":"","overlap":"","content":_vm.approvals.length,"color":"secondary","offset-x":"19","offset-y":"16"}},[_c(VBtn,_vm._g({staticClass:"primary text-capitalize"},on),[_vm._v(" Approvals ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,{staticClass:"mx-auto",staticStyle:{"overflow-y":"scroll"},attrs:{"max-width":"400","max-height":"50vh"}},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"dense":""}},[_c(VListItemTitle,[_vm._v("Approvals")])],1),_c(VDivider),_c(VListItemGroup,{attrs:{"active-class":"secondary--text"}},[_vm._l((_vm.approvals),function(item,i){return [(i < _vm.approvals.length)?_c(VListItem,{key:item.entryNo,attrs:{"link":""},on:{"click":function($event){return _vm.viewApproval(item)}}},[_c(VListItemTitle,[_c('span',{staticClass:"primary--text mr-1"},[_vm._v("# "+_vm._s(item.entryNo))]),_c('span',[_vm._v(_vm._s(("Doc. " + (item.documentNo))))])])],1):_vm._e(),(i < _vm.approvals.length - 1)?_c(VDivider,{key:i}):_vm._e()]})],2)],1)],1)],1),_c(VMenu,{attrs:{"open-on-hover":"","offset-y":"","bottom":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"rounded":"","icon":""}},'v-btn',attrs,false),on),[_c(VBadge,{attrs:{"color":"primary","overlap":"","content":_vm.notifications,"value":_vm.notifications}},[_c(VIcon,[_vm._v("mdi-bell")])],1)],1)]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c(VCard,{attrs:{"min-width":"300"}},[_c(VCardSubtitle,{staticClass:"text-center text-h6 my-0 py-1"},[_vm._v(" Appraisal Reviews ")]),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,_vm._l((_vm.reviews),function(item){return _c(VListItem,{key:item.code,attrs:{"to":{
              name: 'AppraisalAcceptReview',
              params: { reviewerID: _vm.encodeRoute(item.reviewerID) },
              query: { reviewNo: _vm.encodeRoute(item.code) },
            }}},[(item.tooltip)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c(VListItemAction,{staticClass:"mr-3"},[_c(VIcon,{staticClass:"mt-n1",attrs:{"color":"info"}},[_vm._v(" mdi-alert-circle-outline ")])],1),_c(VListItemContent,_vm._g({},on),[_c(VListItemTitle,[_vm._v(" Action required for:   "),_c('span',{staticClass:"text-overline font-weight-bold"},[_vm._v(" "+_vm._s(item.code)+" ")])]),_c(VListItemSubtitle,[_c('span',{staticClass:"text-caption"})])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tooltip))])]):_vm._e()],1)}),1)],1)],1)],1),_c('div',{staticClass:"text-lg-h6 mx-3",staticStyle:{"font-weight":"300"}},[_vm._v(" Welcome "+_vm._s(_vm.userData.name)+" ")]),_c(VMenu,{attrs:{"close-on-content-click":true,"offset-x":"","content-class":"elevation-1","tile":"","nudge-bottom":"52"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"icon":""}},on),[_c('vue-ellipse-progress',{attrs:{"progress":99,"determinate":false,"color":_vm.vuetifyColors.primary,"empty-color":"transparent","empty-color-fill":_vm.emptyColorFill,"size":50,"thickness":3.5,"empty-thickness":3,"lineMode":"out 0","legend":false,"animation":"rs 700 1000","fontSize":"1.5rem","loading":false,"no-data":false}},[_c('span',{staticStyle:{"color":"rgb(0, 68, 139)"},attrs:{"slot":"legend-caption"},slot:"legend-caption"},[(_vm.profilePicture)?_c('span',[_c(VAvatar,[_c(VImg,{style:({ maxHeight: '85%', maxWidth: '85%' }),attrs:{"src":("data:application/jpg;base64," + _vm.profilePicture)}})],1)],1):_c('span',[_vm._v(" "+_vm._s((_vm.userData.email[0] + _vm.userData.email[1]) .toString() .toUpperCase())+" ")])])])],1)]}}]),model:{value:(_vm.linkMenu),callback:function ($$v) {_vm.linkMenu=$$v},expression:"linkMenu"}},[_c(VCard,{staticClass:"px-4",attrs:{"width":"210"}},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"link":"","to":"/approvals"}},[_c(VListItemTitle,{staticStyle:{"color":"#000000"}},[_c(VIcon,[_vm._v("check_box")]),_vm._v(" My Approvals ")],1)],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.downloadFile(_vm.p9Report.base64)}}},[_c(VListItemTitle,{staticStyle:{"color":"#000000"}},[_c(VIcon,[_vm._v("mdi-file-document-outline")]),_vm._v(" P9 Report ")],1)],1),(_vm.company === 'KISEB')?_c(VListItem,{attrs:{"link":"","href":"https://forms.gle/nq5ABjVtdgMFptx2A","target":"_blank"}},[_c(VListItemTitle,{staticStyle:{"color":"#000000"}},[_c(VIcon,[_vm._v("library_books")]),_vm._v(" Innovation Form ")],1)],1):_vm._e(),_c(VListItem,{on:{"click":function($event){return _vm.logout()}}},[_c(VListItemTitle,[_c(VIcon,[_vm._v("logout")]),_vm._v(" Logout ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }