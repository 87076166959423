import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{staticClass:"my-5 elevation-0",attrs:{"height":"100%"}},[_c(VCardTitle,[_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Back ")],1),_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.downloadLink(_vm.document)}}},[_c(VIcon,{attrs:{"left":"","color":"white"}},[_vm._v(" mdi-download ")]),_vm._v(" Download ")],1),_c(VSpacer),_c(VBtn,{staticClass:"white--text orange mx-2",on:{"click":function($event){_vm.dialog = true}}},[_c(VIcon,{attrs:{"left":"","color":"white"}},[_vm._v(" mdi-close ")]),_vm._v(" Disagree With Appraisal Review ")],1),_c(VBtn,{staticClass:"white--text green",on:{"click":_vm.acceptReview}},[_c(VIcon,{attrs:{"left":"","color":"white"}},[_vm._v(" mdi-check ")]),_vm._v(" Accept Appraisal Review ")],1)],1),_c(VDivider),_c(VRow,[_c(VCol,{attrs:{"cols":"8","offset":"2"}},[_c(VCardText,{staticClass:"elevation-1 my-1"},_vm._l((_vm.getPages(_vm.document)),function(i){return _c('pdf',{key:i,attrs:{"src":_vm.document,"page":i}})}),1)],1)],1)],1),_c(VDialog,{attrs:{"width":"50%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v("Reject With A reason")]),_c(VCardText,[_c(VTextarea,{attrs:{"label":"Reason","outlined":"","placeholder":"Enter a reason"},model:{value:(_vm.disagreementReason),callback:function ($$v) {_vm.disagreementReason=$$v},expression:"disagreementReason"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"deep-orange white--text"},on:{"click":_vm.rejectReview}},[_vm._v(" Reject ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }