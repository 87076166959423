import { render, staticRenderFns } from "./TrainingAttachment.vue?vue&type=template&id=45b8b97c&"
import script from "./TrainingAttachment.vue?vue&type=script&lang=js&"
export * from "./TrainingAttachment.vue?vue&type=script&lang=js&"
import style0 from "./TrainingAttachment.vue?vue&type=style&index=0&id=45b8b97c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports