import call from "../../service/http";
import constants from "./DashboardConstants";
export default {
  namespaced: true,
  state: {
    notifications: [],
    sideLinks: [],
    drawer: null,
    orgData: [],
    p9Report: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.stateField] = payload.data;
    },
    ADD_LINK: (state, link) => {
      if (state.sideLinks.find((l) => l.name === link.name) === undefined) {
        state.sideLinks.push(link);
        state.sideLinks.sort((a, b) => a.order - b.order);
      }
    },
    RESET: (state) => {
      for (const key in state) state[key] = [];
    },
    CHANGE_DRAWER: (state, payload) => {
      state.drawer = payload;
    },
    SET_RIGHT_DRAWER: (state, payload) => {
      state.rightDrawer = payload;
    },
    UPDATE_LINKS: (state, payload) => {
      state.sideLinks = payload;
    },
  },
  getters: {
    links: (state) => state.sideLinks,
    orgData: (state) => state.orgData,
    p9Report: (state) => state.p9Report,
  },
  actions: {
    changeDrawer: ({ commit }, data) => {
      commit("CHANGE_DRAWER", data);
    },
    setRightDrawer: ({ commit }, data) => {
      commit("SET_RIGHT_DRAWER", data);
    },
    getChartData: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getChartData)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { stateField: "orgData", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    sendOrgChart: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.postOrgChart, data)
        .then((res) => {
          // console.log(res);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getp9Report: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.getp9, data)
        .then((res) => {
          // console.log(res.data);
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { stateField: "p9Report", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
