import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VNavigationDrawer,{staticClass:"drawerColor",attrs:{"permanent":_vm.$vuetify.breakpoint.mdAndUp,"mini-variant":_vm.$vuetify.breakpoint.mdAndUp ? _vm.mini : false,"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('AppAccount',{attrs:{"mini":_vm.mini}}),_vm._l((_vm.userData.is_admin ? _vm.adminLinks : _vm.links),function(link,i){return _c(VList,{key:i,staticClass:"drawer-list"},[(!_vm.mini && !link.hasSubs && link.name !== undefined)?_c(VListItem,{key:i,staticClass:"white--text",attrs:{"link":"","to":!link.hasSubs ? link.to : ''}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(link.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(link.name))])],1):_vm._e(),(_vm.mini && !link.hasSubs && link.name !== undefined)?_c(VListItem,{key:i,staticClass:"text-center",attrs:{"to":!link.hasSubs ? link.to : '',"link":""}},[_c(VTooltip,{staticClass:"mini--link",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(link.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(link.name))])])],1):_vm._e(),(!_vm.mini && link.hasSubs)?_c(VListGroup,{attrs:{"value":false},on:{"click":function($event){$event.stopPropagation();return _vm.routeTo(link.to)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(link.icon))])],1),_c(VListItemTitle,{staticClass:"white--text"},[_vm._v(_vm._s(link.name))])]},proxy:true}],null,true)},_vm._l((link.subs),function(sub,idx){return _c(VListItem,{key:idx,staticClass:"white--text",attrs:{"to":sub.to,"link":""}},[_c(VListItemIcon,{staticClass:"ml-10"},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(sub.icon))])],1),_c(VListItemTitle,{staticClass:"ml-1"},[_vm._v(_vm._s(sub.name))])],1)}),1):_vm._e(),(_vm.mini && link.hasSubs)?_c(VListGroup,{staticClass:"text-center",attrs:{"value":false},on:{"click":function($event){$event.stopPropagation();return _vm.routeTo(link.to)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VTooltip,{staticClass:"mini--link",attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(link.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(link.name))])])]},proxy:true}],null,true)},_vm._l((link.subs),function(sub,idx){return _c(VListItem,{key:idx,attrs:{"to":sub.to,"link":""}},[_c('span',[_c(VIcon,{staticStyle:{"color":"#ffffff"}},[_vm._v(_vm._s(sub.icon))])],1)])}),1):_vm._e()],1)})],2),_c('AppNav',{attrs:{"drawer":_vm.drawer,"changeDrawer":_vm.changeDrawer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }