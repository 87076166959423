import call from "@kinetics254/cassandra-base/service/http";
import constants from "./LeaveConstants";
import AuthService from "../../../cassandra-base/modules/auth/AuthService";

export default {
  namespaced: true,
  state: {
    applications: [],
    HandOverApprovals: [],
    handOverReasons: [],
    handoverPeople: [],
    application: {},
    appDialog: false,
    approvalApplications: [],
    viewApplication: false,
    ApprovalApplication: [],
    isCalender: false,
    preview: false,
    loadingPreview: false,
    previewData: [],
    filterValue: "",
    listFilterValue: "",
    validLeave: false,
    loadingv: false,
    approvalModal: {
      open: false,
      application_code: "",
      status: false,
    },
    acceptModal: {
      open: false,
      application_code: "",
      status: false,
    },
    leaveSheetDialog: {
      open: false,
      leaveSheet: "",
    },
    leaveValidationData: {
      status: "",
      show: false,
      data: [],
      message: "",
    },
    leaveAttachment: [],
    setQuery: "",
    handoverReportTemplate: "",
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.stateField] = payload.data;
    },
    SET_LEAVE_VALIDATION_DATA: (state, payload) => {
      const { show, status, data, message } = payload;
      state.leaveValidationData = { ...state, show, status, data, message };
    },
    SET_APPROVAL_MODAL: (state, payload) => {
      // eslint-disable-next-line camelcase
      const { open, application_code, status } = payload;
      state.approvalModal = { ...state, open, application_code, status };
    },
    SET_ACCEPT_MODAL: (state, payload) => {
      // eslint-disable-next-line camelcase
      const { open, application_code, status } = payload;
      state.acceptModal = { ...state, open, application_code, status };
    },
    SET_LEAVE_SHEET: (state, payload) => {
      const { open, leaveSheet } = payload;
      state.leaveSheetDialog = { ...state, open, leaveSheet };
    },
  },
  getters: {
    isCalender: (state) => state.isCalender,
    leaveGetter: (state) => (value) => state[value],
  },
  actions: {
    refresh: ({ dispatch, commit }, data) => {
      Promise.all([dispatch(data.entity, data.data)])
        .then((response) => {
          response.map((res) => {
            commit(res.mutation, res.data);
          });
          commit("MUTATE", { stateField: "setQuery", data: data.data.status });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {});
    },
    getApplications: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.applications(data.employeeNo))
        .then((res) => {
          commit("MUTATE", { stateField: "applications", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    refreshApprovalApplications: async (context, data) => {
      const res = await call("get", constants.getApprovalApplications);
      return { mutation: "SET_APPROVAL_APPLICATIONS", data: res.data.data };
    },
    loading: ({ commit }, payload) => {
      commit("loader/SET_LOADING", payload, { root: true });
    },
    getApprovalApplications: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call(
        "get",
        constants.applicationsAll(AuthService.user.profile.user_id)
      ).then((res) => {
        commit("loader/SET_LOADING", false, { root: true });
        commit("MUTATE", {
          stateField: "approvalApplications",
          data: res.data.data,
        });
      });
    },
    getApplication: ({ commit }, payload) => {
      console.log("testpaylod", payload);
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.application(payload.application_code))
        .then((res) => {
          commit("MUTATE", {
            stateField: "application",
            data: Array.isArray(res.data.data)
              ? res.data.data[0]
              : res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          Event.$emit("ApiError", "Unable to fetch leave application");
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    viewApplication: ({ commit }, payload) => {
      commit("SET_VIEW_APPLICATION", payload);
    },
    validateLeave: ({ commit }, data) => {
      commit("MUTATE", { stateField: "loadingv", data: true });
      call("post", constants.validateLeave, data)
        .then((res) => {
          commit("MUTATE", { stateField: "loadingv", data: false });
          const validateResponse = JSON.parse(res.data.data);
          console.log("validateResponse", validateResponse);
          if (validateResponse.error) {
            Event.$emit("ApiError", validateResponse.error);
          }
          commit("SET_LEAVE_VALIDATION_DATA", {
            show: true,
            status: validateResponse.error ? "error" : "success",
            data: validateResponse.error ? [] : validateResponse,
            message: validateResponse.error
              ? validateResponse.error
              : validateResponse,
          });
        })
        .catch((error) => {
          console.log("error", error.response.data.message);
          Event.$emit("ApiError", error.response.data.message);
          commit("MUTATE", { stateField: "loadingv", data: false });
        });
    },
    setPreview: ({ commit }, data) => {
      commit("SET_PREVIEW", data);
    },
    setLoadingPreview: ({ commit }, data) => {
      commit("MUTATE", { stateField: "loadingPreview", data });
    },
    setPreviewData: ({ commit }, data) => {
      commit("MUTATE", { stateField: "previewData", data });
    },
    /** application CRUD */
    submitApplication: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.applicationsAll(null), data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message + ": Application Made");
          const app = res.data.data;
          dispatch("refresh", {
            entity: "getApplications",
            data: { employeeNo: app.employee_no, status: "Open" },
          });
          Event.$emit("returnToApplication", {
            path: "application",
            query: app.status,
          });
        })
        .catch((error) => {
          dispatch("setLoadingPreview", false);
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    submitAllowanceandApplication: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.applicationsAll, data.leavedata)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message + ": Application Made");
          const app = res.data.data;
          data.allowancedata.associatedLeave = app.application_code;
          dispatch("allowance/storeAllowance", data.allowancedata, {
            root: true,
          });
          dispatch("refresh", {
            entity: "getApplications",
            data: { employeeNo: app.employee_no, status: "Open" },
          });
          Event.$emit("returnToApplication", {
            path: "application",
            query: app.status,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    sendApproval: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.sendApproval, data.data)
        .then((res) => {
          dispatch("refresh", {
            entity: "getApplications",
            data: { employeeNo: data.employeeNo, status: "Review" },
          });
          Event.$emit("ApiSuccess", "Application sent for approval");
          dispatch("getApplication", { ...data.data });
          commit("MUTATE", { stateField: "appDialog", data: false });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
          commit("MUTATE", { stateField: "appDialog", data: false });
        });
    },
    patchApplication: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.patchApplication, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          dispatch("refresh", {
            entity: "getApplications",
            data: {
              employeeNo: res.data.data.employee_no,
              status: res.data.data.status,
            },
          });
          Event.$emit("returnToApplication", {
            path: "/leave/application",
            query: res.data.data.status,
          });
        })
        .catch((error) => {
          dispatch("setPreview", false);
          dispatch("setLoadingPreview", false);
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**
     * Handover reasons
     * @param commit
     */
    handOverReasons: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", "hrmis/leave_application/handover-reasons")
        .then((res) => {
          console.log(res);
          commit("MUTATE", {
            stateField: "handOverReasons",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          Event.$emit("ApiError", "Unable to fetch handover reasons");
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    acceptRejectHandover: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.patchHandover, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    cancelApplication: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.cancelApplication, data.data)
        .then((res) => {
          dispatch("refresh", {
            entity: "getApplications",
            data: { employeeNo: data.employeeNo, status: "Open" },
          });
          dispatch("getApplication", { ...data.data });
          Event.$emit("ApiInfo", "Application cancelled");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    archiveApplication: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.archiveApplication, data.data)
        .then((res) => {
          dispatch("refresh", {
            entity: "getApplications",
            data: { employeeNo: data.employeeNo },
          });
          Event.$emit("ApiSuccess", "Application Deleted");
          dispatch("getApplication", { ...data.data });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    /** approval crud */
    setApplicationModal: ({ commit }, data) => {
      commit("SET_APPROVAL_MODAL", data);
    },
    approve: ({ dispatch, commit }, data) => {
      console.log(data);
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.approve, data)
        .then((res) => {
          dispatch("refresh", {
            entity: "refreshApprovalApplications",
            data: {},
          });
          commit("SET_APPROVAL_MODAL", { application_code: "", open: false });
          Event.$emit("ApiSuccess", "Application Approved");
          dispatch("getApplication", { ...data });
          commit("MUTATE", { stateField: "appDialog", data: false });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("SET_APPROVAL_MODAL", { application_code: "", open: false });
          Event.$emit("ApiError", error.response.data.message);
          commit("MUTATE", { stateField: "appDialog", data: false });
        });
    },
    reject: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.reject, data)
        .then((res) => {
          dispatch("refresh", {
            entity: "refreshApprovalApplications",
            data: {},
          });
          commit("SET_APPROVAL_MODAL", { application_code: "", open: false });
          Event.$emit("ApiInfo", "Application Rejected");
          dispatch("getApplication", { ...data });
          commit("MUTATE", { stateField: "appDialog", data: false });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("SET_APPROVAL_MODAL", { application_code: "", open: false });
          Event.$emit("ApiError", error.response.data.message);
          commit("MUTATE", { stateField: "appDialog", data: false });
        });
    },

    /** attachments */
    getLeaveSheet: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.getLeaveSheet(data.employeeNo), data.data)
        .then((res) => {
          const leaveSheet = res.data.data;
          commit("SET_LEAVE_SHEET", { open: true, leaveSheet });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    getLeaveAttachment: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.getAttachment, data.data)
        .then((res) => {
          commit("MUTATE", {
            stateField: "leaveAttachment",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getHandOverApprovals: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.HandOverApprovals).then((res) => {
        commit("loader/SET_LOADING", false, { root: true });
        commit("MUTATE", {
          stateField: "HandOverApprovals",
          data: res.data.data,
        });
      });
    },
    AcceptHandOver: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.patchApplication, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", res.data.message);
          dispatch("refresh", {
            entity: "getApplications",
            data: {
              employeeNo: res.data.data.employee_no,
              status: res.data.data.status,
            },
          });
          Event.$emit("returnToApplication", {
            path: "/leave/application",
            query: res.data.data.status,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getHandoverReportTemplate: ({ commit }) => {
      call("get", constants.handoverReportTemplate).then((res) => {
        commit("MUTATE", {
          stateField: "handoverReportTemplate",
          data: res.data.data,
        });
      });
    },
    getHandoverPeople: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.handoverPeople(payload))
        .then((res) => {
          commit("MUTATE", {
            stateField: "handoverPeople",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          Event.$emit("ApiError", "Unable to fetch HandOver");
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
