import AppDashboard from "@kinetics254/cassandra-base/app/layout/AppDashboard";
import Auth from "@kinetics254/cassandra-base/modules/auth/middleware/Auth";
import Profile from "./views/Profile";

export default [
  {
    path: "/profile",
    component: AppDashboard,
    children: [
      {
        path: "",
        components: {
          view: Profile,
        },
        meta: {
          middleware: Auth,
          breadcrumb: [{ text: "profile", disabled: false, href: "/profile" }],
        },
      },
    ],
  },
];
